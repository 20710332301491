<template>
    <ProdutosDisponiveis :matricula="currentUser.matricula" />
    
</template>

<script lang="js">    
    import ProdutosDisponiveis from '../../components/extrato/ProdutosDisponiveis.vue'
    

    export default {
        data(){
            return {
                
                
                
                
            }
        },
         methods: {
            
        },
        
        computed: {
			currentUser() {
				return this.$store.state.auth.user;
			}
		},

        components: { ProdutosDisponiveis }
    }

</script>