import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const API_BASE_URL = `${PortalCooperadoUtils.apiCooperado}`;
const RESOURCE_V1 = `/v1/produtos-disponiveis`;
const RESOURCE_V2 = `/v1/familias-produtos-disponiveis`;

class ProdutosDisponivel {

    getProdutosDisponivel(matricula, familia) {

        if(familia !== null){

            if(familia.codFamilia === null)
                familia.codFamilia = '*';

            return axios.get(API_BASE_URL + RESOURCE_V1 + "/" + matricula +'?familia=' + familia.codFamilia );
        }
        else
            return axios.get(API_BASE_URL + RESOURCE_V1 + "/" + matricula);
    }

    getFamiliaProdutosDisponivel(matricula) {
        return axios.get(API_BASE_URL + RESOURCE_V2 + "/" + matricula );
    }

    
}

export default new ProdutosDisponivel();
